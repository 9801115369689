html, body, #root {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Helvetica', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

p {
    margin: 0;
}